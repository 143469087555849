@font-face {
  font-family: Frutiger;
  src: url(./assets/fonts/FrutigerLTArabic-45Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Frutiger;
  src: url(./assets/fonts/FrutigerLTArabic-55Roman.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Frutiger;
  src: url(./assets/fonts/FrutigerLTArabic-65Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Frutiger;
  src: url(./assets/fonts/FrutigerLTArabic-75Black.ttf);
  font-weight: 900;
}

button {
  font-family: inherit;
}
